import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_toast = _resolveComponent("ion-toast")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.name), 1)
            ]),
            _: 1
          }),
          (_ctx.store.state.auth)
            ? (_openBlock(), _createBlock(_component_ion_title, {
                key: 0,
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.store.state.auth.email), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, {
            slot: "end",
            class: "ion-margin-end"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.state.online ? '' : 'offline'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            class: "ion-margin-end"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.state.weldsSync ? 'syncing…' : ''), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_toast, {
        "is-open": _ctx.store.state.weldsSync,
        message: "syncing...",
        duration: 2000
      }, null, 8, ["is-open"])
    ]),
    _: 1
  }))
}