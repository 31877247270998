
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {BackgroundMode} from "@awesome-cordova-plugins/background-mode";
import { defineComponent } from 'vue';
import { useStore, MUTATIONS, ACTIONS } from './store'

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet
    },
    setup() {
        const store = useStore()
        window.addEventListener('online', () => {
            store.commit(MUTATIONS.ONLINE)
            store.dispatch(ACTIONS.GET_MANUFACTURERS)
            store.dispatch(ACTIONS.GET_MODELS)
            store.dispatch(ACTIONS.GET_PIPES)
            store.dispatch(ACTIONS.AUTH_USER)
            store.dispatch(ACTIONS.SYNC_WELDS)
        });
        window.addEventListener('offline', () => store.commit(MUTATIONS.OFFLINE))
        window.addEventListener('deviceready', () => { BackgroundMode.enable() })
        return { store }
    }
});
