
import { IonHeader, IonToolbar, IonTitle, IonLabel, IonToast } from '@ionic/vue';
import { defineComponent } from "vue";
import { useStore } from '@/store'

export default defineComponent({
    components: { IonHeader, IonToolbar, IonTitle, IonLabel, IonToast },
    props: {
        name: { type: String, required: true }
    },
    setup () {
        const store = useStore()

        return { store }
    }
})
