import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, MutationTree, ActionTree, } from 'vuex';
import VuexPersistence from "vuex-persist";
import axios from "axios";
import config from '../config'

axios.defaults.withCredentials = true
axios.defaults.baseURL = config.baseURL

axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error && error.response && error.response.status === 401) {
        store.commit(MUTATIONS.SET_AUTH, null)
    }
    return error
})

// interfaces for our State and todos
export type Online = boolean;
export type Auth = { id: number, name: string, email: string, weld_identity: string };
export type Manufacturers = { id: bigint; label: string; };
export type Models = { id: bigint; manufacturer_id: bigint, label: string, cylinder: number, gauge: string };
export type Pipes = { id: bigint; diameter: number; walls: Walls[] };
export type Walls = { id: bigint; pe: number; pn: number, wall: number, sdr: number };
export type Weld = { id: null|bigint, manufacturer_id: null|bigint, model_id: null|bigint, pipe_id: null|bigint,
    pipe_wall_id: null|bigint, gauge: null|string, drag_pressure: null|number, clean: null|boolean,
    backing: null|boolean, temperature: null|boolean, created_at: null|string, client: null|string, label: null|string,
    latitude: null|string, longitude: null|string, asset: null|string
};
export type State = {
    online: Online,
    auth: null|Auth,
    newWeld: Weld,
    manufacturers: Manufacturers[],
    models: Models[],
    pipes: Pipes[],
    welds: Weld[],
    weldLog: Weld[],
    nextWeldPage: null|string,
    weldsSync: boolean,
    weldsSyncTimeout: number,
    weldEmailing: boolean
}

export const key: InjectionKey<Store<State>> = Symbol();
const state: State = {
    online: true,
    auth: null,
    newWeld: {
        id: null,
        client: null,
        label: null,
        asset: null,
        manufacturer_id: null,
        model_id: null,
        pipe_id: null,
        pipe_wall_id: null,
        gauge: null,
        drag_pressure: null,
        clean: false,
        temperature: false,
        backing: null,
        latitude: null,
        longitude: null,
        created_at: null
    },
    manufacturers: [],
    models: [],
    pipes: [],
    welds: [],
    weldLog: [],
    nextWeldPage: null,
    weldsSync: false,
    weldsSyncTimeout: 0,
    weldEmailing: false
};

/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */
export const enum MUTATIONS {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    SET_AUTH = 'SET_AUTH',
    MANUFACTURERS = 'MANUFACTURERS',
    MODELS = 'MODELS',
    PIPES = 'PIPES',
    NEW_WELD = 'NEW_WELD',
    START_TIMER = 'START_TIMER',
    DELETE_WELD = 'DELETE_WELD',
    LOG_WELD = 'LOG_WELD',
    SYNC_WELD_LOG = 'SYNC_WELD_LOG',
    NEXT_WELD_PAGE = 'NEXT_WELD_PAGE',
    WELDS_SYNCING = 'WELDS_SYNCING',
    WELDS_SYNC_TIMEOUT = 'WELDS_SYNC_TIMEOUT',
    WELD_EMAILING = 'WELD_EMAILING'
}
const mutations: MutationTree<State> = {
    [MUTATIONS.ONLINE](state) {
        state.online = true;
    },
    [MUTATIONS.OFFLINE](state) {
        state.online = false;
    },
    [MUTATIONS.SET_AUTH](state, data) {
        state.auth = data;
    },
    [MUTATIONS.MANUFACTURERS](state, manufacturers) {
        state.manufacturers = manufacturers;
    },
    [MUTATIONS.MODELS](state, models) {
        state.models = models;
    },
    [MUTATIONS.PIPES](state, models) {
        state.pipes = models;
    },
    [MUTATIONS.NEW_WELD](state, { key, value }) {
        state.newWeld[key as keyof typeof state.newWeld] = value;
    },
    [MUTATIONS.START_TIMER](state) {
        const date = new Date()

        // Duplicate the newWeld object to remove reactivity
        const newWeld = {} as Weld
        Object.assign(newWeld, state.newWeld)
        newWeld.created_at = date.toJSON()

        state.newWeld.clean = false
        state.newWeld.temperature = false
        state.newWeld.backing = false

        state.welds.push(newWeld)
    },
    [MUTATIONS.DELETE_WELD](state, weld: Weld ) {
        for (let i = 0; i < state.welds.length; i++) {
            if (state.welds[i].created_at === weld.created_at) {
                state.welds.splice(i, 1)
            }
        }
    },
    [MUTATIONS.LOG_WELD](state, weld: Weld ) {
        state.weldLog.push(weld)
    },
    [MUTATIONS.SYNC_WELD_LOG](state, welds: Weld[] ) {
        welds.forEach(w => {
            // wca - Weld Created At
            // wlca - Weld Log Created At
            const wca = new Date(w.created_at as string).toString()
            let added = false
            for (let i = 0; i < state.weldLog.length; i++) {
                const wlca = new Date(state.weldLog[i].created_at as string).toString()
                if (wlca == wca) {
                    state.weldLog.splice(i, 1, w)
                    added = true
                }
            }
            if (!added) {
                state.weldLog.push(w)
            }
        })
    },
    [MUTATIONS.NEXT_WELD_PAGE](state, value: null|string ) {
        state.nextWeldPage = value
    },
    [MUTATIONS.WELDS_SYNCING](state, value: boolean ) {
        state.weldsSync = value
    },
    [MUTATIONS.WELDS_SYNC_TIMEOUT](state ) {
        state.weldsSyncTimeout = new Date().getTime() + (60 * 1000)
    },
    [MUTATIONS.WELD_EMAILING](state, value: boolean) {
        state.weldEmailing = value
    },
};

/*
 * Actions
 * Perform async tasks, then mutate state
 */

export const enum ACTIONS {
    REGISTER_USER = 'REGISTER_USER',
    LOGIN_USER = 'LOGIN_USER',
    AUTH_USER = 'AUTH_USER',
    GET_MANUFACTURERS = 'GET_MANUFACTURERS',
    GET_MODELS = 'GET_MODELS',
    GET_PIPES = 'GET_PIPES',
    SYNC_WELDS = 'SYNC_WELDS',
    LOAD_MORE = 'LOAD_MORE',
    EMAIL_WELD = 'EMAIL_WELD',
}
const actions: ActionTree<State, any> = {
    [ACTIONS.REGISTER_USER](store, payload) {
        return new Promise((resolve, reject) => {
            axios.get('sanctum/csrf-cookie').then(() => {
                axios.post('api/register', payload)
                    .then((res) => {
                        return resolve(res)
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            })
        })
    },
    [ACTIONS.LOGIN_USER](store, payload) {
        return new Promise((resolve, reject) => {
            axios.get('sanctum/csrf-cookie').then(() => {
                axios.post('api/login', payload)
                    .then((res) => {
                        return resolve(res)
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            })
        })
    },
    [ACTIONS.AUTH_USER](store, payload) {
        return new Promise((resolve, reject) => {
            axios.get('api/user', payload)
                .then(({ data }) => {
                    store.commit(MUTATIONS.SET_AUTH, data.data)
                    return resolve
                })
                .catch((err) => {
                    store.commit(MUTATIONS.SET_AUTH, null)
                    return reject(err)
                })
        })
    },
    [ACTIONS.GET_MANUFACTURERS](store) {
        axios.get('api/manufacturers')
            .then(({ data }) => {
                store.commit(MUTATIONS.MANUFACTURERS, data.data);
            });
    },
    [ACTIONS.GET_MODELS](store) {
        axios.get('api/models')
            .then(({ data }) => {
                store.commit(MUTATIONS.MODELS, data.data);
            });
    },
    [ACTIONS.GET_PIPES](store) {
        axios.get('api/pipes')
            .then(({ data }) => {
                store.commit(MUTATIONS.PIPES, data.data);
            });
    },
    [ACTIONS.SYNC_WELDS](store) {
        const welds = store.state.weldLog.filter(w => !w.id)
        if(welds.length > 0 && !store.state.weldsSync && store.state.online) {
            store.commit(MUTATIONS.WELDS_SYNCING, true)
            axios.post('api/welds', welds)
                .then((res) => {
                    store.commit(MUTATIONS.WELDS_SYNCING, false)
                    store.commit(MUTATIONS.SYNC_WELD_LOG, res.data.data)
                })
                .catch(() => {
                    store.commit(MUTATIONS.WELDS_SYNCING, false)
                })
        } else if (!store.state.weldsSync) {
            const timeout = store.state.weldsSyncTimeout
            const now = new Date().getTime()
            if (now > timeout && store.state.online) {
                store.commit(MUTATIONS.WELDS_SYNCING, true)
                axios.get('api/welds')
                    .then((res) => {
                        store.commit(MUTATIONS.WELDS_SYNC_TIMEOUT)
                        store.commit(MUTATIONS.SYNC_WELD_LOG, res.data.data)
                        store.commit(MUTATIONS.NEXT_WELD_PAGE, res.data.links.next)
                        store.commit(MUTATIONS.WELDS_SYNCING, false)
                    })
                    .catch(() => {
                        store.commit(MUTATIONS.WELDS_SYNCING, false)
                    })
            }
        }
    },
    [ACTIONS.LOAD_MORE](store) {
        if(store.state.nextWeldPage && store.state.online) {
            store.commit(MUTATIONS.WELDS_SYNCING, true)
            const url = new URL(store.state.nextWeldPage)
            axios.get(url.pathname + url.search)
                .then((res) => {
                    store.commit(MUTATIONS.WELDS_SYNCING, false)
                    store.commit(MUTATIONS.SYNC_WELD_LOG, res.data.data)
                    store.commit(MUTATIONS.NEXT_WELD_PAGE, res.data.links.next)
                })
                .catch(() => {
                    store.commit(MUTATIONS.WELDS_SYNCING, false)
                })
        }
    },
    [ACTIONS.EMAIL_WELD](store, payload) {
        store.commit(MUTATIONS.WELD_EMAILING, true)
        axios.post('api/email', payload)
            .then(() => {
                store.commit(MUTATIONS.WELD_EMAILING, false)
            })
            .catch(() => {
                store.commit(MUTATIONS.WELD_EMAILING, false)
            })
    }
};

export const store = createStore<State>({
    state,
    mutations,
    actions,
    plugins: [new VuexPersistence().plugin]
});

// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
